import React from "react";

import { styled } from "@stitches/react";
import tw from "twin.macro";

const LabelRoot = styled("label", {
  ...tw`ml-0.5 font-medium`,
  variants: {
    size: {
      md: tw`text-md mb-0.75`,
      lg: tw`text-base mb-0.75`,
      none: {},
    },
    disabled: {
      true: tw`opacity-50`,
    },
  },
  defaultVariants: {
    size: "md",
    disabled: false,
  },
});

interface LabelProps extends React.ComponentProps<typeof LabelRoot> {
  required?: boolean;
}

export const Label: React.FC<LabelProps> = ({ children, required = false, ...props }) => {
  return (
    <LabelRoot {...props}>
      {children} {required && <span tw="text-red-600">*</span>}
    </LabelRoot>
  );
};
