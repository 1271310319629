import React from "react";

import { Popover } from "@headlessui/react";

import { useDatePickerState } from "@/app/core/display/picker/date/hooks/useDatePickerState";
import type { FCWithChildren } from "@/app/core/types/tsx";

interface DatePickerTriggerProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "as" | "ref" | "type">,
    FCWithChildren {}

export const DatePickerTrigger = React.forwardRef<
  HTMLButtonElement,
  DatePickerTriggerProps
>(({ children, ...props }, ref) => {
  const state = useDatePickerState();

  return (
    <Popover.Button ref={ref} data-state={state.open ? "open" : "closed"} {...props}>
      {children}
    </Popover.Button>
  );
});

DatePickerTrigger.displayName = "DatePickerTrigger";
