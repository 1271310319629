import type React from "react";
import { createContext } from "react";

import type { useLilius } from "use-lilius";

export const DatePickerContext = createContext<
  | (ReturnType<typeof useLilius> & {
      open: boolean;
      time?: [number, number];
      setTime: React.Dispatch<React.SetStateAction<[number, number]>>;
    })
  | null
>(null);
