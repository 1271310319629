import { useContext } from "react";

import { DatePickerContext } from "@/app/core/display/picker/date/context/DatePickerContext";

export const useDatePickerState = () => {
  const context = useContext(DatePickerContext);

  if (context === null) {
    throw new Error("Date Picker components must be wrapped in <DatePicker />");
  }

  return context;
};
