import type { DurationLikeObject, ToHumanDurationOptions } from "luxon";
import { DateTime, Duration } from "luxon";

type DateFormat = "relative" | "full-month" | string;
type MaybeDateTime = Date | DateTime;

/**
 * Format a given Date using Luxon or default
 * to a value if not defined
 */
export const format = (
  date: Date | null | undefined,
  dateFormat: DateFormat,
  fallback = "-"
) => {
  if (!date) {
    return fallback ?? "-";
  }

  const parsedDate = DateTime.fromJSDate(new Date(date));

  switch (dateFormat) {
    case "relative":
      return parsedDate.toRelative({ locale: "es" }) ?? fallback;
    case "full-month":
      return parsedDate.toJSDate().toLocaleDateString("es-ES", {
        month: "long",
        year: "numeric",
      });
    case "full-day":
      return parsedDate.toJSDate().toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    default:
      return parsedDate.toFormat(dateFormat, { locale: "es" });
  }
};

/**
 * Convert a date to Luxon's DateTime
 */
export const toDateTime = (date: MaybeDateTime): DateTime => {
  return "startOf" in date ? date : DateTime.fromJSDate(date);
};

/**
 * Check if two dates are equal
 */
export const isEqual = (a: MaybeDateTime, b: MaybeDateTime) => {
  return (
    toDateTime(a).startOf("day").toMillis() === toDateTime(b).startOf("day").toMillis()
  );
};

/**
 * Check if a date is today
 */
export const isToday = (date: MaybeDateTime) => {
  return isEqual(date, DateTime.now());
};

/**
 * Check if a date is within a given date range
 */
export const inRange = (
  date: MaybeDateTime,
  first: MaybeDateTime,
  last: MaybeDateTime
) => {
  const millis = toDateTime(date).toMillis();
  return millis >= toDateTime(first).toMillis() && millis <= toDateTime(last).toMillis();
};

/**
 * Compare the two dates and return -1, 0 or 1
 */
export const compareAsc = (a: Date, b: Date) => {
  const diff = new Date(a).getTime() - new Date(b).getTime();

  if (diff < 0) {
    return -1;
  } else if (diff > 0) {
    return 1;
  }

  return diff;
};

/**
 * Return the array of dates within the specified time interval.
 */
export const eachDayOfInterval = (interval: { start: Date; end: Date }): Date[] => {
  const startDate = new Date(interval.start);
  const endDate = new Date(interval.end);
  const startTime = startDate.getTime();
  const endTime = endDate.getTime();

  if (startTime > endTime) {
    return [];
  }

  const dates: Date[] = [];

  const currentDate = startDate;
  currentDate.setHours(0, 0, 0, 0);

  while (currentDate.getTime() <= endTime) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0, 0);
  }

  return dates;
};

export const durationToHuman = (
  dur: Duration,
  options: { smallestUnit?: keyof DurationLikeObject } & ToHumanDurationOptions = {}
): string => {
  const { smallestUnit = "milliseconds", ...opts } = options;
  const units: (keyof DurationLikeObject)[] = [
    "years",
    "months",
    "days",
    "hours",
    "minutes",
    "seconds",
    "milliseconds",
  ];

  const smallestIdx = units.indexOf(smallestUnit);

  const entries = Object.entries(
    dur
      .shiftTo(...units)
      .normalize()
      .toObject()
  )
    .filter(([_unit, amount], idx) => amount > 0 && idx <= smallestIdx)
    .slice(0, 1);

  const dur2 = Duration.fromObject(
    entries.length === 0 ? { [smallestUnit]: 0 } : Object.fromEntries(entries)
  );

  return dur2.toHuman(opts);
};
