import { styled } from "@stitches/react";
import tw from "twin.macro";

export const CalendarDay = styled("button", {
  ...tw`flex items-center justify-center flex-1 aspect-square rounded-xl border border-transparent
  text-md duration-200 transition-all active:scale-[0.97] [font-variant-numeric: tabular-nums]`,
  variants: {
    inMonthRange: {
      true: tw`font-medium`,
      false: tw`text-gray-600`,
    },
    selected: {
      true: tw`bg-gray-900 text-white font-medium`,
      false: tw`hover:(bg-gray-150 text-gray-800)`,
    },
    today: {
      true: tw`font-bold bg-gray-150 text-accent`,
      false: {},
    },
    inSelectRange: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      selected: true,
      inSelectRange: false,
      css: tw`bg-gray-900 text-white font-medium`,
    },
    {
      selected: true,
      inSelectRange: true,
      css: tw`bg-gray-150 text-gray-900 hover:(bg-gray-200)`,
    },
    {
      selected: true,
      today: true,
      inSelectRange: false,
      css: tw`bg-gray-900 text-white`,
    },
  ],
});
