import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";
import { Text } from "@/app/core/ui/components/Text";

export const Badge = styled(Text, {
  ...tw`rounded-full px-1 py-0.25 transition-colors`,
  variants: {
    noGap: {
      true: {},
      false: tw`gap-0.75`,
    },
    noFlex: {
      true: {},
      false: tw`flex items-center`,
    },
    wrap: {
      true: {},
      false: tw`whitespace-nowrap`,
    },
  },
  defaultVariants: {
    noGap: false,
    noFlex: false,
    wrap: false,
  },
});
