import React, { useCallback, useEffect } from "react";

import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUp,
} from "lucide-react";

import { CalendarDay } from "@/app/core/display/picker/date/components/CalendarDay";
import { useDatePickerState } from "@/app/core/display/picker/date/hooks/useDatePickerState";
import { Badge } from "@/app/core/ui/components/Badge";
import { Button } from "@/app/core/ui/components/Button";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { date } from "@/app/core/utils";

interface DatePickerCalendarProps {
  onSelect?: (selected: Date[]) => void;
  disallowDeselect?: boolean;
  type?: "range" | "single";
  footer?: "actions" | "time-select" | "none";
}

export const DatePickerCalendar: React.FC<DatePickerCalendarProps> = ({
  onSelect,
  disallowDeselect = false,
  type = "range",
  footer = "actions",
}) => {
  const {
    select,
    deselect,
    selectRange,
    selected,
    isSelected,
    calendar,
    viewing,
    viewNextMonth,
    viewPreviousMonth,
    viewNextYear,
    viewPreviousYear,
    viewToday,
    time,
    setTime,
  } = useDatePickerState();

  useEffect(() => {
    if (!time || type !== "single") {
      onSelect?.(selected);
      return;
    }

    const [hours, minutes] = time;
    const [current] = selected;
    if (current) {
      const cc = new Date(current);
      cc.setHours(hours, minutes, 0, 0);
      onSelect?.([cc]);
      return;
    }

    onSelect?.(selected);
  }, [onSelect, selected, time, type]);

  /**
   * Handle a day change when the type is "range"
   */
  const handleRangeDayChange = useCallback(
    (day: Date) => {
      const sorted = selected.sort(date.compareAsc);

      if (sorted.length === 0) {
        select(day);
        return;
      }

      if (isSelected(day)) {
        if (selected.length === 1) {
          if (!disallowDeselect) deselect(day);
          return;
        }

        if (date.isEqual(day, selected[selected.length - 1])) {
          select(sorted[0], true);
          return;
        }

        const range = date.eachDayOfInterval({
          start: sorted[0],
          end: day,
        });
        const diff = sorted.filter((d) =>
          range.map((a) => a.getTime()).includes(d.getTime())
        );
        selectRange(diff[0], diff[diff.length - 1], true);
        return;
      }

      try {
        selectRange(sorted[0], day, true);
      } catch {
        // @todo Fix this (invalid range)
      }
    },
    [deselect, disallowDeselect, isSelected, select, selectRange, selected]
  );

  /**
   * Handle a day change when the mode is "single"
   */
  const handleSingleDayChange = useCallback(
    (day: Date) => {
      if (selected.length === 0) {
        select(day);
        return;
      }

      if (isSelected(day)) {
        if (!disallowDeselect) deselect(day);
        return;
      }

      deselect(selected);
      select(day);
    },
    [deselect, disallowDeselect, isSelected, select, selected]
  );

  /**
   * Handle the day selection
   */
  const handleDayChange = useCallback(
    (day: Date) => {
      if (type === "range") {
        return handleRangeDayChange(day);
      }

      if (type === "single") {
        return handleSingleDayChange(day);
      }
    },
    [handleRangeDayChange, handleSingleDayChange, type]
  );

  /**
   * Handle an hour change
   */
  const handleHourChange = useCallback(
    (op: "inc" | "dec", major = false) => {
      setTime((s) => {
        let [hours, minutes] = s;

        if (op === "inc" && major) {
          hours += 1;
        } else if (op === "inc") {
          minutes += 5;
        } else if (major) {
          hours -= 1;
        } else {
          minutes -= 5;
        }

        if (minutes >= 60) {
          hours += 1;
          minutes = 0;
        }

        if (hours >= 24) {
          hours = 0;
        }

        if (minutes < 0) {
          hours -= 1;
          minutes = 55;
        }

        if (hours < 0) {
          hours = 23;
        }

        minutes = Math.ceil(minutes / 5) * 5;
        return [hours, minutes];
      });
    },
    [setTime]
  );

  return (
    <>
      <Flex tw="justify-between items-center">
        <Flex tw="gap-0.5">
          <Button
            type="button"
            onClick={viewPreviousYear}
            size="none"
            theme="white"
            tw="p-0.75 rounded-lg"
            spring
          >
            <ChevronsLeft size="14" />
          </Button>
          <Button
            type="button"
            onClick={viewPreviousMonth}
            size="none"
            theme="white"
            tw="p-0.75 rounded-lg"
            spring
          >
            <ChevronLeft size="14" />
          </Button>
        </Flex>

        <Text tw="capitalize" weight="medium" size="md">
          {date.format(viewing, "MMMM yyyy")}
        </Text>

        <Flex tw="gap-0.5">
          <Button
            type="button"
            onClick={viewNextMonth}
            size="none"
            theme="white"
            tw="p-0.75 rounded-lg"
            spring
          >
            <ChevronRight size="14" />
          </Button>
          <Button
            type="button"
            onClick={viewNextYear}
            size="none"
            theme="white"
            tw="p-0.75 rounded-lg"
            spring
          >
            <ChevronsRight size="14" />
          </Button>
        </Flex>
      </Flex>

      <Flex tw="mt-2 flex-col gap-0.75">
        <Flex tw="w-full gap-1">
          {calendar[0][0].map((day) => (
            <Text
              key={String(day)}
              tw="capitalize flex-1"
              align="center"
              color="gray-600"
              size="md"
              weight="bold"
            >
              {new Intl.DateTimeFormat("es-ES", {
                weekday: "short",
              }).format(day)}
            </Text>
          ))}
        </Flex>

        {calendar[0].map((week) => (
          <Flex key={`week-${week[0]}`} tw="w-full gap-1">
            {week.map((day) => (
              <Flex tw="flex-1" key={String(day)}>
                <CalendarDay
                  type="button"
                  today={date.isToday(date.toDateTime(day))}
                  selected={isSelected(day)}
                  inMonthRange={date.inRange(
                    day,
                    date.toDateTime(viewing).startOf("month"),
                    date.toDateTime(viewing).endOf("month")
                  )}
                  inSelectRange={
                    isSelected(day) &&
                    !date.isEqual(day, selected[0]) &&
                    !date.isEqual(day, selected[selected.length - 1])
                  }
                  onClick={() => handleDayChange(day)}
                >
                  {date.format(day, "dd")}
                </CalendarDay>
              </Flex>
            ))}
          </Flex>
        ))}

        <Flex tw="w-full gap-0.75">
          {footer === "actions" &&
            (
              [
                ["Hoy", viewToday],
                [
                  "Esta semana",
                  () => {
                    viewToday();
                    selectRange(
                      date.toDateTime(new Date()).startOf("week").toJSDate(),
                      date.toDateTime(new Date()).endOf("week").toJSDate(),
                      true
                    );
                  },
                ],
                [
                  "Este mes",
                  () => {
                    viewToday();
                    selectRange(
                      date.toDateTime(new Date()).startOf("month").toJSDate(),
                      date.toDateTime(new Date()).endOf("month").toJSDate(),
                      true
                    );
                  },
                ],
              ] as const
            ).map(([text, action]) => (
              <Badge
                key={text}
                as="button"
                type="button"
                onClick={action}
                size="sm"
                color="gray-800"
                weight="medium"
                tw="bg-gray-150 border border-gray-300"
              >
                {text}
              </Badge>
            ))}

          {footer === "time-select" && selected.length !== 0 && (
            <Flex center tw="w-full mt-1 gap-0.25 text-gray-700">
              <Button
                type="button"
                onClick={() => handleHourChange("dec", true)}
                size="none"
                theme="white"
                tw="w-2.5 h-2.5 rounded-lg"
                spring
              >
                <ChevronsUp size="13" />
              </Button>
              <Button
                type="button"
                onClick={() => handleHourChange("dec", false)}
                size="none"
                theme="white"
                tw="w-2.5 h-2.5 rounded-lg"
                spring
              >
                <ChevronUp size="13" />
              </Button>
              <Text
                color="gray-900"
                tw="bg-gray-150 px-1.25 py-0.5 rounded-md mx-0.5 [font-variant-numeric: tabular-nums]"
              >
                {time &&
                  `${String(time[0]).padStart(2, "0")}:${String(time[1]).padStart(
                    2,
                    "0"
                  )}`}
              </Text>
              <Button
                type="button"
                onClick={() => handleHourChange("inc", false)}
                size="none"
                theme="white"
                tw="w-2.5 h-2.5 rounded-lg"
                spring
              >
                <ChevronDown size="13" />
              </Button>
              <Button
                type="button"
                onClick={() => handleHourChange("inc", true)}
                size="none"
                theme="white"
                tw="w-2.5 h-2.5 rounded-lg"
                spring
              >
                <ChevronsDown size="13" />
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};
