export const delimited = (n: number) => {
  return new Intl.NumberFormat("de-DE").format(n);
};

export const toCurrency = (n: number, digits = 2, locale = "de-DE", currency = "EUR") =>
  new Intl.NumberFormat(locale, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    currency,
    style: "currency",
  }).format(n);

export const toPercentage = (n: number, digits = 0) =>
  new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    style: "percent",
  }).format(n);

export const map = (value: number, x1: number, y1: number, x2: number, y2: number) =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
