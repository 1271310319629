import React, { useEffect, useState } from "react";

import { styled } from "@stitches/react";
import { AlertCircle, Eye, EyeOff } from "lucide-react";
import type { FieldError } from "react-hook-form";
import tw from "twin.macro";

import { useToast } from "@/app/core/display/toast/store/useToast";
import { Label } from "@/app/core/forms/components/Label";
import { Absolute } from "@/app/core/ui/components/Absolute";
import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";

export const InputElement = styled("input", {
  ...tw`rounded-md outline-none w-full relative`,
  ...tw`bg-gray-150 border border-transparent`,
  ...tw`disabled:(bg-gray-150! text-gray-800 cursor-not-allowed) transition-colors`,

  variants: {
    size: {
      lg: tw`px-2 py-1.5 text-base`,
      md: tw`px-1.5 py-1 text-md`,
      none: {},
    },
    error: {
      true: tw`border-red-600 bg-white`,
      false: tw`hover:(border-gray-300) focus:(bg-white border-gray-300) not-placeholder-shown:(bg-white border-gray-300)`,
    },
  },

  defaultVariants: {
    error: false,
    size: "md",
  },
});

export interface InputProps
  extends Omit<React.ComponentProps<typeof InputElement>, "error"> {
  name: string;
  error?: FieldError;
  label?: string;
  hideErrorIcon?: boolean;
  errorAsToast?: boolean;
  suffix?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      hideErrorIcon = false,
      size = "md",
      errorAsToast = false,
      required,
      children,
      suffix,
      ...props
    },
    ref
  ) => {
    const [type, setType] = useState(props.type);

    useEffect(() => {
      if (errorAsToast && error?.message) {
        useToast.getState().showToast({
          message: error.message,
          type: "error",
        });
      }
    }, [error?.message, errorAsToast]);

    return (
      <Flex tw="flex-col gap-0.5 w-full">
        <Flex tw="flex-col">
          {label ? (
            <Label
              size={size}
              htmlFor={`input_${props.name}`}
              tw="focus:text-red-600"
              required={required}
              disabled={props.disabled}
            >
              {label}
            </Label>
          ) : null}

          <Box tw="relative">
            <InputElement
              id={`input_${props.name}`}
              error={Boolean(error)}
              type={type}
              ref={ref}
              size={size}
              css={{ ...(suffix && tw`pr-3`), ...props.css }}
              {...props}
            />

            {props.type === "password" ? (
              <Absolute align="centerRight">
                <Box
                  as="button"
                  type="button"
                  tw="mr-1 text-gray-500 z-10"
                  tabIndex={-1}
                  onClick={() => setType((s) => (s === "password" ? "text" : "password"))}
                >
                  {type === "password" ? <Eye size="18" /> : <EyeOff size="18" />}
                </Box>
              </Absolute>
            ) : null}

            {!hideErrorIcon && error ? (
              <Absolute align="vertical">
                <AlertCircle tw="text-red-600 left-full ml-0.5" size="18" />
              </Absolute>
            ) : null}

            {children}

            {suffix && (
              <Absolute align="centerRight">
                <Text tw="pr-1" color="gray-600" weight="medium">
                  {suffix}
                </Text>
              </Absolute>
            )}
          </Box>
        </Flex>

        {error && !errorAsToast ? (
          <Text size="sm" color="red-600" tw="ml-0.5">
            {error.message}
          </Text>
        ) : null}
      </Flex>
    );
  }
);

Input.displayName = "Input";
