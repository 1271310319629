import React, { useMemo, useState } from "react";

import { Popover } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import tw from "twin.macro";
import type { Options } from "use-lilius";
import { Day, useLilius } from "use-lilius";

import { DatePickerCalendar } from "@/app/core/display/picker/date/components/DatePickerCalendar";
import { DatePickerContext } from "@/app/core/display/picker/date/context/DatePickerContext";
import type { FCWithChildren } from "@/app/core/types/tsx";

interface DatePickerProps extends FCWithChildren, Options {
  onSelect?: (selected: Date[]) => void;
  position?: "top" | "bottom" | "right" | "left";
  panelProps?: Omit<React.HTMLProps<HTMLDivElement>, "as" | "ref">;
  calendarProps?: React.ComponentProps<typeof DatePickerCalendar>;
  containerProps?: Omit<React.HTMLProps<HTMLDivElement>, "as" | "ref">;
  selectedTime?: [number, number];
}

export const DatePicker: React.FC<DatePickerProps> = ({
  children,
  onSelect,
  position = "bottom",
  panelProps,
  calendarProps,
  containerProps,
  selectedTime,
  ...options
}) => {
  const [time, setTime] = useState<[number, number]>(selectedTime ?? [0, 0]);
  const datePicker = useLilius({
    numberOfMonths: 1,
    weekStartsOn: Day.MONDAY,
    ...options,
  });

  const { style, x, y } = useMemo(() => {
    let animateY = 0;
    let animateX = 0;
    let panelStyle = tw``;

    if (position === "bottom") {
      animateY = -10;
      panelStyle = tw`mt-0.5`;
    } else if (position === "top") {
      animateY = 10;
      panelStyle = tw`mb-0.5 bottom-full`;
    } else if (position === "left") {
      animateX = -10;
    } else if (position === "right") {
      animateX = -10;
      panelStyle = tw`ml-0.5 left-full top-1/2 transform -translate-y-1/2`;
    }

    return {
      x: animateX,
      y: animateY,
      style: panelStyle,
    };
  }, [position]);

  return (
    <Popover tw="relative z-50 w-full" {...containerProps}>
      {({ open }) => (
        <DatePickerContext.Provider value={{ ...datePicker, open, time, setTime }}>
          {children}

          <AnimatePresence>
            {open && (
              <motion.div
                layout
                initial={{ opacity: 0, y, x }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                exit={{ opacity: 0, y, x }}
                transition={{
                  type: "spring",
                  bounce: 0.4,
                  duration: 0.4,
                }}
                css={style}
                tw="absolute"
              >
                <Popover.Panel
                  static
                  tw="min-w-[32rem] bg-white p-2 shadow-2xl w-full border border-gray-200 rounded-lg"
                  css={style}
                  {...panelProps}
                >
                  <DatePickerCalendar onSelect={onSelect} {...calendarProps} />
                </Popover.Panel>
              </motion.div>
            )}
          </AnimatePresence>
        </DatePickerContext.Provider>
      )}
    </Popover>
  );
};
